export { contentClass };

import { eventBus } from "@/eventBus";
import { store } from "@/store/store";

class contentClass {
  constructor() {
    import(
      /* webpackChunkName: "purchaseClass" */
      /* webpackMode: "lazy" */ "./purchaseClass"
    ).then((purchase) => {
      this.purchase = new purchase.purchaseClass();
    });

    eventBus.$on("featured-list", (payload) => {
      this.featuredList(payload);
    });

    eventBus.$on("category-list", (payload) => {
      this.categoryList(payload);
    });

    eventBus.$on("getContentList", (payload) => {
      this.getContentList(payload);
    });

    eventBus.$on("landingPage_contents", (payload) => {
      this.getLandingPageContents(payload);
    });

    eventBus.$on("get-seasonnum-listing", (payload) => {
      this.getSeasonNumListing(payload);
    });

    eventBus.$on("get-episode-list", (payload) => {
      this.getEpisodeListing(payload);
    });

    eventBus.$on("getRelatedcontents", (payload) => {
      this.getRelatedContents(payload);
    });

    eventBus.$on("getContentDetail", (payload) => {
      console.log("inside on of getContentDetail----");
      this.getContentDetail(payload);
    });

    eventBus.$on("search-api-call", (data) => {
      this.getSearchContent(data);
    });

    eventBus.$on("search-suggestion-contents", (data) => {
      this.getSearchSuggestion(data);
    });

    eventBus.$on("genre-list", () => {
      this.genreList();
    });

    eventBus.$on("list-genre-contents", (data) => {
      this.genreContents(data);
    });

    eventBus.$on("getContentStreamUrl", (data) => {
      this.getContentStreamUrl(data);
    });

    eventBus.$on("language-list", () => {
      this.listLanguage();
    });

    eventBus.$on("get-content-details", (data) => {
      this.getContentDetails(data);
    });

    eventBus.$on("grid-see-all-data", (data) => {
      this.getGridCards(data);
    });

    eventBus.$on("get-bundle-items", (data) => {
      this.getBundleItems(data);
    });

    eventBus.$on("search-page-call", (data) => {
      this.getSearchedData(data);
    });
  }

  featuredList(data) {
    store
      .dispatch("contentList", data)
      .then((response) => {
        if (response.data) {
          eventBus.$emit("featured-response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  categoryList(data) {
    store
      .dispatch("contentList", data)
      .then((response) => {
        if (response.data) {
          if (data.type && data.type === "bold") {
            eventBus.$emit("bold-contents", response.data);
          } else {
            eventBus.$emit(`${data.id}`, response.data);
          }
        }
      })
      .catch((error) => console.log(error));
  }

  getLandingPageContents(data) {
    store
      .dispatch("listContent", data)
      .then((response) => {
        eventBus.$emit("landing_page_contents", response);
      })
      .catch((error) => console.log(error));
  }

  getSeasonNumListing(data) {
    store
      .dispatch("listContent", data)
      .then((response) => {
        if (response.data.totalcount) {
          eventBus.$emit("season_listing_response", response.data.totalcount);
        } else {
          eventBus.$emit("season_listing_response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  getEpisodeListing(data) {
    store.commit("setEpisodePaginationInProgress", true);
    store
      .dispatch("listContent", data)
      .then((response) => {
        if (response.data) {
          eventBus.$emit("send-episode-list", response.data);
        }
      })
      .catch((error) => console.log(error));
  }
  // categories(payload) {
  //   let counter = 0;
  //   let contentsList = [];
  //   payload.forEach((element) => {
  //     if (element.endpoint === "firebase/content") return;

  //     let load = {
  //       endpoint: element.endpoint,
  //       data: {
  //         token: "uA6h3HVZen1f9fIQ",
  //         category: element.parameters.category
  //           ? element.parameters.category
  //           : "",
  //         objecttype: element.parameters.objecttype
  //           ? element.parameters.objecttype
  //           : "",
  //       },
  //     };

  //     store
  //       .dispatch("contentList", load)
  //       .then((response) => {
  //         counter = counter + 1;
  //         if (!response.data.reason) {
  //           contentsList.push({ data: response.data, screen: element });
  //           if (counter === payload.length - 1) {
  //             eventBus.$emit("categories", contentsList);
  //           }
  //         }
  //       })
  //       .catch((error) => console.log(error));
  //   });
  // }

  genreList() {
    // let objGenre = {
    //   category: payload.parameters.category.toString(),
    //   objecttype: payload.parameters.objecttype
    // };
    // console.log("load", objGenre);

    store
      .dispatch("genreList")
      .then((response) => {
        if (response.data.reason) {
          console.log("error");
        } else {
          eventBus.$emit("genre-items", response.data.data);
        }
        // console.log("genre-list-response", response.data.data);
      })
      .catch((err) => console.log(err));
  }

  getContentList(payload) {
    if (payload.endpoint === "firebase/content") return;
    store
      .dispatch("contentList", payload)
      .then((response) => {
        if (payload.title === "featured") {
          // eventBus.$emit(`${payload.eventName}`, response);
          eventBus.$emit("featured", response);
        }
        // else {
        //   console.log(`${payload.title}`, response);
        //   eventBus.$emit(`${payload.title}`, response);
        // }
      })
      .catch((error) => console.log(error));
  }

  getContentDetails(payload) {
    store
      .dispatch("getContent", payload)
      .then((res) => {
        eventBus.$emit("contents-for-watchlist", res.data);
      })
      .catch((err) => console.log("getting wrongg".err));
  }

  getContentDetail(payload) {
    console.log("getContentDetail payloaddd", payload);
    store
      .dispatch("contentDetail", payload)
      .then((response) => {
        if (!response.data.reason) {
          console.log("complete-details--- of the respective content---- ", response);
          eventBus.$emit(`contentDetail-response-${payload.contentid}`, response);
        } else {
          eventBus.$emit(`contentDetail-error-response-${payload.contentid}`, response);
        }
      })
      .catch((error) => console.log(error));
  }

  getBundleItems(payload) {
    store
      .dispatch("listBundleItem", payload)
      .then((response) => {
        if (!response.data.reason) {
          console.log("response.data", response.data);
          eventBus.$emit("bundle-item-response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }
  getRelatedContents(payload) {
    store
      .dispatch("relatedContents", payload)
      .then((response) => {
        if (response.data) {
          eventBus.$emit("relatedcontents-response", response.data);
        }
      })
      .catch();
  }

  getSearchSuggestion(payload) {
    // eventBus.$emit("loading", true);
    store
      .dispatch("listSuggestions", payload)
      .then((res) => {
        // console.log("suggestions", res.data);
        // eventBus.$emit("loading", false);
        if (res.data.reason) {
          eventBus.$emit("show-error-alert", res.data.reason);
        } else {
          eventBus.$emit("send-suggestions-response", res.data);
        }
      })
      .catch((err) => console.log(err));
  }

  getSearchContent(payload) {
    eventBus.$emit("loading", true);
    store
      .dispatch("contentSearch", payload)
      .then((response) => {
        // console.log("search response", response);
        eventBus.$emit("loading", false);
        if (response.data.reason) {
          eventBus.$emit("show-error-alert", response.data.reason);
        } else {
          eventBus.$emit("render-search-to-dom", response.data);
        }
      })
      .catch((err) => console.log(err));
  }

  getSearchedData(payload) {
    store
      .dispatch("contentSearch", payload)
      .then((response) => {
        // console.log("search response", response);
        if (response.data.reason) {
          eventBus.$emit("no-resp", response.data.reason);
        } else {
          eventBus.$emit("show-search-in-dom", response.data);
					eventBus.$on("render-search-to-dom", response.data);
					eventBus.$on("search-query-string", response.data);
					//eventBus.$on("show-search-in-dom", response.data);
        }
      })
      .catch((err) => console.log(err));
  }

  genreContents(payload) {
    // let load = {
    //   category: payload.menu.parameters.category,
    //   objecttype: payload.menu.parameters.objecttype,
    //   genre: payload.genre,
    //   token: "uA6h3HVZen1f9fIQ"
    // };
    store
      .dispatch("genreContentList", payload)
      .then((response) => {
        if (response.data.reason) {
          console.log(response.data.reason);
          eventBus.$emit("show-genre-placeholder");
        } else {
          eventBus.$emit("genre-specific-contents", response.data);
        }
      })
      .catch((err) => console.log(err));
  }

  listLanguage() {
    store
      .dispatch("listi18nLanguage")
      .then((res) => {
        eventBus.$emit("all-language", res);
      })
      .catch((err) => console.log(err));
  }

  //content stream url//
  getContentStreamUrl(payload) {
    store
      .dispatch("contentStreamUrl", payload)
      .then((response) => {
        if (response.data) {
          eventBus.$emit("contentStreamUrl-response", response.data);
        }
      })
      .catch(() => console.log(error));
  }

  getGridCards(payload) {
    store
      .dispatch("contentList", payload)
      .then((response) => {
        if (response.data) {
          eventBus.$emit("get-seeAll-data", response.data);
        }
      })
      .catch((err) => console.log(err));
  }
}
